import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const AffiliateDisclaimerPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>AFFILIATE DISCLAIMER</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              Please note that our travel site operates as an affiliate,
              providing a price comparison service for tours and attractions
              worldwide. When you click on a booking link on our site and make a
              reservation on the provider's website, we may earn a commission at
              no additional cost to you.
            </p>

            <p>
              We strive to provide accurate and up-to-date information regarding
              prices, availability, and other details of the tours and
              attractions. However, please be aware that the prices and
              availability displayed on our site may differ from the actual
              rates and availability at the time of booking on the provider's
              website.
            </p>

            <p>
              We recommend that you review all the relevant information,
              including pricing, terms and conditions, cancellation policies,
              and any other details, on the provider's website before making any
              bookings. We do not assume any responsibility for the accuracy,
              completeness, or timeliness of the information provided on our
              site.
            </p>

            <p>
              Thank you for using our travel service and supporting our work. We
              appreciate your trust and assure you that we strive to provide the
              best possible experience for our users.
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default AffiliateDisclaimerPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Affiliate Disclaimer | Travel-Lingual"}
      description={
        "Affiliate Disclaimer: Learn about our affiliate relationships and how they support our mission to provide you with valuable travel information and resources."
      }
      pathname={location.pathname}
    />
  );
};
